import { useMemo } from 'react';

import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useContentfulPage } from 'hooks/useContentfulPage';

import { ImageBlock } from 'components/ImageBlock';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { SignupLink } from 'components/Links';

import {
  Container,
  HeroContainer,
  Section,
  SectionImage,
  SectionVideo,
} from 'components/Container';

import { List } from 'components/List';
import { CTANotice } from 'components/Notice';
import { TrustPilot } from 'components/TrustPilot';
import { Cards } from 'components/Cards';
import { swapYourSpreadsheetCards, uploadHoldingsCards } from 'page-data/portfolio-tracker';
import { ContentBlock } from 'components/ContentBlock';
import { PricingBlock } from 'components/PricingBlock';
import videoThumbnail from 'images/shared-page-assets/youtube-thumbnails/marcus-customer-story.png';

import { StatsBlock } from 'components/StatsBlock';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const NoMoreSpreadsheet: FC<LocalizedPageProps> = () => {
  const {
    translations: {
      supportedCurrencies,
      builtForInvestors,
      investorsJustLikeYou,
      stock,
      mutualFund,
    },
  } = useContentfulLocale();

  const {
    translations: { sharesightsAwardWinningPerformanceAndTaxReporting, pricingNote },
    cards,
    sections,
  } = useContentfulPage();

  const localisedTaxCards = useMemo(
    () => cards?.filter(card => card.cardIdentifier.startsWith('tax-alt-')),
    [cards]
  );
  const performanceInsightsItems = [
    {
      id: 'performance-insight-1',
      content: `Option to benchmark against any ${stock}, ETF or ${mutualFund}.`,
    },
    {
      id: 'performance-insight-2',
      content:
        'See the impact of capital gains, dividends and currency fluctuations on your portfolio and against your benchmark.',
    },
    {
      id: 'performance-insight-3',
      content: 'Calculate your total annual return over any time period.',
    },
    {
      id: 'performance-insight-4',
      content: 'Options to include open and closed (sold) positions.',
    },
    {
      id: 'performance-insight-5',
      content:
        'Group by market, sector, industry, investment type, country, or your own custom groups.',
    },
  ];

  const taxableIncomeReportImg = useGatsbyImage({
    name: 'shared-page-assets/localised/taxable-income-report',
    alt: 'Taxable Income Report',
  });

  return (
    <Layout>
      <Seo />

      <HeroContainer background="cream" skipColoration>
        <Section>
          <h1>Swap your spreadsheet for Sharesight</h1>
          <p>
            Tracking your portfolio in a spreadsheet is a hassle. Our award-winning portfolio
            tracker makes it easy.
          </p>

          <br />
          <br />

          <SignupLink asButton />
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'no-more-spreadsheet/sharesight-product-line-graph',
            alt: "User Interface of Sharesight's portfolio management tool featuring a performance line graph",
            loading: 'eager',
          })}
          boxShadow={false}
        />
      </HeroContainer>

      <Container>
        <Section>
          <h2>No more spreadsheets</h2>
          <p>
            70% of Sharesight users previously used a spreadsheet to track their portfolio. See for
            yourself why they made the switch to Sharesight.
          </p>
        </Section>
        <SectionVideo videoId="gPkPqizr0nE" thumbnailUrl={videoThumbnail} />
      </Container>
      <Cards
        header="Automate your portfolio tracking"
        subHeader="Replace your Excel spreadsheet with a purpose-built portfolio tracker, built by investors, for investors."
        cards={swapYourSpreadsheetCards}
      />
      <Container>
        <Section>
          <h2>All your investments in one place, not all over the place</h2>
          <p>
            {sharesightsAwardWinningPerformanceAndTaxReporting} You can also track cash and over{' '}
            {supportedCurrencies} currencies, as well as unlisted investments such as fixed interest
            and investment properties. And it’s all wrapped up in an easy-to-use online portfolio
            tracker.
          </p>
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'no-more-spreadsheet/portfolio-dividend-tracker',
            alt: "User Interface of Sharesight's portfolio and dividend tracker with property, investment, bitcoin and currency icons",
          })}
          boxShadow={false}
        />
      </Container>
      <Container>
        <Section>
          <h2>Powerful performance insights</h2>
          <p>
            Sharesight includes everything you need to manage your investment portfolio, and can be
            tailored to suit your needs.
          </p>
          <List items={performanceInsightsItems} />
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'shared-page-assets/portfolio-overview',
            alt: "User Interface of Sharesight's portfolio and dividend tracker with graphic highlights over the capital gain, dividends and currency gain return metrics.",
          })}
          boxShadow={false}
        />
      </Container>
      <CTANotice
        header="Start by tracking up to 10 holdings for free"
        note="Upgrade any time to track more holdings and unlock advanced features."
      />
      <Cards
        cards={localisedTaxCards}
        header={builtForInvestors}
        subHeader={investorsJustLikeYou}
      />
      {sections && (
        <ContentBlock
          header={sections[0]?.header}
          textContent={sections[0]?.textContent}
          image={taxableIncomeReportImg}
        />
      )}
      <CTANotice
        header="Start by tracking up to 10 holdings for free"
        note="Upgrade any time to track more holdings and unlock advanced features."
      />
      <Cards
        header="Upload your holdings, and we'll take it from there"
        cards={uploadHoldingsCards}
      />
      <TrustPilot />
      <CTANotice
        header="Start by tracking up to 10 holdings for free"
        note="Upgrade any time to track more holdings and unlock advanced features."
      />
      <StatsBlock />
      {/* This pricingNote which comes from contentful should always be a string */}
      <PricingBlock pricingNote={pricingNote} />

      <ImageBlock variation="awards" />
    </Layout>
  );
};

export default NoMoreSpreadsheet;
